import React from 'react'
import PropTypes from 'prop-types'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import { nord } from 'react-syntax-highlighter/dist/esm/styles/prism'

// best syntax styles: coldarkDark, dracula, duotoneSea, materialDark, materialOceanic, nord, synthwave84, vscDarkPlus, xonokai

const CodeBlock = ({
  language,
  value
}) => (
  <SyntaxHighlighter language={language} style={nord}>
    {value}
  </SyntaxHighlighter>
)

CodeBlock.propTypes = {
  language: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired
}

export default CodeBlock
