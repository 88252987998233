import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import styled from '@emotion/styled'
import { Box } from '@rebass/grid/emotion'

import { GridContainer, ContentContainer } from '../../components/Grid'
import { Gutter } from '../../utils/styles'
import BlogLink from './BlogLink'
import BlogNav from './BlogNav'

const PostsContainer = styled.div({
  display: 'flex',
  flexWrap: 'wrap'
})

const BlogList = ({
  client
}) => {
  const [entries, setEntries] = useState([])
  const [categoryFilter, setCategoryFilter] = useState('All')

  useEffect(() => {
    client.getEntries()
      .then(({ items }) => {
        const sortedEntries = _.orderBy(items, entry => entry.fields.createdOn, ['desc'])
          .filter(({ sys }) => sys.contentType.sys.id === 'blogPost')
          .filter(({ fields }) => {
            if (categoryFilter === 'All') return true
            if (!fields.categories) return false

            const categories = fields.categories.map(subField => subField.fields.name)
            return categories.includes(categoryFilter)
          })
        setEntries(sortedEntries)
      })
  }, [categoryFilter])

  if (!entries.length) return null

  const [latestEntry, ...otherEntries] = entries
  return (
    <>
      <BlogNav
        client={client}
        setCategoryFilter={setCategoryFilter}
        categoryFilter={categoryFilter}
      />
      <GridContainer>
        <ContentContainer
          width={[1]}
          p={[Gutter.HALF, Gutter.FULL, Gutter.FULL]}
        >
          <Box width={[1]}>
            <BlogLink
              entryFields={latestEntry.fields}
              entrySys={latestEntry.sys}
              isLatest
            />
          </Box>
          <PostsContainer>
            {otherEntries.map(({ fields, sys }) => (
              <Box width={[1, 1, 1 / 3]} key={sys.id}>
                <BlogLink
                  entryFields={fields}
                  entrySys={sys}
                />
              </Box>
            ))}
          </PostsContainer>
        </ContentContainer>
      </GridContainer>
    </>
  )
}

BlogList.propTypes = {
  client: PropTypes.object.isRequired,
}

export default BlogList
