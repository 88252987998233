import React from 'react'
import styled from '@emotion/styled'
import { Link } from 'gatsby'
import { transparentize } from 'polished'

import { Colors } from '../../utils/styles'


const StyledLink = styled(Link)({
  textDecoration: 'none',
  fontWeight: 700,
  color: Colors.PRIMARY,
  borderRadius: 4,
  padding: '8px 12px',
  ':visited': {
    color: Colors.PRIMARY,
  },
  ':hover': {
    background: transparentize(0.85, Colors.PRIMARY)
  }
})

const BlogHome = () => (
  <div>
    <StyledLink to="/blog">⬅︎ Back to All Blog Posts</StyledLink>
  </div>
)

export default BlogHome
