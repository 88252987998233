import React from 'react'
import PropTypes from 'prop-types'
import { transparentize } from 'polished'
import styled from '@emotion/styled'

import { Colors } from '../../utils/styles/Color'

const Container = styled.div({
  background: transparentize(0.85, Colors.PRIMARY),
  fontSize: 12,
  fontWeight: 700,
  padding: '4px 8px',
  color: Colors.PRIMARY,
  borderRadius: 4,
  display: 'inline',
  margin: 8,
  ':first-child': {
    marginLeft: '0 !important',
  }
})

const CategoryTag = props => (
  <Container>
    {props.label}
  </Container>
)

CategoryTag.defaultProps = {
  label: 'CategoryLabel'
}

CategoryTag.propTypes = {
  label: PropTypes.string,
}

export default CategoryTag
