import React, { useEffect, useState } from 'react'
import ReactMarkdown from 'react-markdown'
import { format } from 'date-fns'
import PropTypes from 'prop-types'
import _ from 'lodash'
import gfm from 'remark-gfm'
import styled from '@emotion/styled'

import { H1, Paragraph } from '../../components/Typography'
import { GridContainer } from '../../components/Grid'
import CodeBlock from '../../components/CodeBlock'
import { Colors } from '../../utils/styles/Color'
import BlogHome from './BlogHome'
import CategoryTag from './CategoryTag'
import AuthorAvatar from './AuthorAvatar'

import './blogStyles.css'

const BlogPostContainer = styled.div({
  maxWidth: 800,
  margin: '0 auto',
  padding: '0 24px 48px',
})

const CategoryTagContainer = styled.div({
  display: 'flex',
})

const PublishInfo = styled.div({
  display: 'flex',
  alignItems: 'center',
  marginTop: 16,
  marginBottom: 36,
})

const Author = styled(Paragraph)({
  fontWeight: 700,
  color: Colors.PRIMARY,
  lineHeight: '1 !important',
  marginBottom: '8px !important',
  marginTop: '0 !important',
})

const StyledDate = styled(Paragraph)({
  color: Colors.BLACK,
  lineHeight: '1 !important',
  marginBottom: '8px !important',
  marginTop: '0 !important',
})

const SingleBlog = ({ client, slug }) => {
  const [entry, setEntry] = useState({})

  useEffect(() => {
    const entryId = _.last(slug.split('-'))
    client.getEntry(entryId).then((clientEntry) => {
      setEntry(clientEntry)
    })
  }, [])

  if (_.isEmpty(entry)) return null

  const {
    title,
    createdOn,
    author,
    image,
    markdownText,
    description,
    categories,
  } = entry.fields

  const HeroImage = styled.div({
    background: `url(https:${image.fields.file.url}), #000000`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    width: '100%',
    height: 500,
    marginBottom: 48,
  })

  return (
    <div style={{ paddingTop: 24 }}>
      <GridContainer>
        <BlogHome />
      </GridContainer>
      <BlogPostContainer>
        <div id="blog-post">
          <CategoryTagContainer>
            {categories
              && categories.map(({ fields, sys }) => (
                <CategoryTag key={sys.id} label={fields.name} />
              ))}
          </CategoryTagContainer>
          <H1 style={{ marginTop: 12 }}>{title}</H1>
          <Paragraph style={{ fontSize: 24 }}>{description}</Paragraph>
          <PublishInfo>
            <AuthorAvatar image={author.fields.avatar.fields.file.url} />
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginLeft: 16,
              }}
            >
              <Author>{author.fields.name}</Author>
              <StyledDate>{format(new Date(createdOn), 'PPP')}</StyledDate>
            </div>
          </PublishInfo>
          <HeroImage />
          {/* <Image
            src={`https:${image.fields.file.url}`}
            alt=""
          /> */}
          <ReactMarkdown
            plugins={[gfm]}
            renderers={{ code: CodeBlock }}
          >
            {markdownText}
          </ReactMarkdown>
        </div>
        <div style={{ marginTop: 120 }}>
          <BlogHome />
        </div>
      </BlogPostContainer>
    </div>
  )
}

SingleBlog.propTypes = {
  client: PropTypes.object.isRequired,
  slug: PropTypes.object.isRequired,
}

export default SingleBlog
