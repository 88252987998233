import React from 'react'
import { Router } from '@reach/router'
import styled from '@emotion/styled'

import Layout from '../../components/Layout'

import BlogList from './BlogList'
import SingleBlog from './SingleBlog'
import { Colors } from '../../utils/styles'

const contentful = require('contentful')

const client = contentful.createClient({ // eslint-disable-line
  space: '0ffzicssmmyo',
  accessToken: process.env.GATSBY_CONTENTFUL_ACCESS_TOKEN
})

const BlogContainer = styled.div({
  backgroundColor: Colors.WHITE
})

const LayoutBlog = () => (
  <Layout>
    <BlogContainer>
      <Router>
        <BlogList path="/blog" client={client} />
        <SingleBlog path="/blog/post/:slug" client={client} />
      </Router>
    </BlogContainer>
  </Layout>
)

export default LayoutBlog
