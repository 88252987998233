import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

import { Colors } from '../../utils/styles/Color'

const avatarSize = 42

const Avatar = styled.div(props => ({
  background: `url(https:${props.image}), ${Colors.PRIMARY}`,
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  borderRadius: avatarSize,
  width: avatarSize,
  height: avatarSize,
}))

const AuthorAvatar = props => <Avatar image={props.image} />

export default AuthorAvatar

AuthorAvatar.defaultProps = {
  image: 'https://res.cloudinary.com/pulsedatatools/image/upload/v1585747228/pulse-analytics-marketing/logo/pulse-outlook_2x.png', // eslint-disable-line
}

AuthorAvatar.propTypes = {
  image: PropTypes.string,
}
