import React from 'react'
import PropTypes from 'prop-types'
import { format } from 'date-fns'
import { Link } from 'gatsby'
import { transparentize } from 'polished'

import styled from '@emotion/styled'
import { H2, Paragraph } from '../../components/Typography'
import { Colors } from '../../utils/styles'

import AuthorAvatar from './AuthorAvatar'
import CategoryTag from './CategoryTag'

const BlogLinkContainer = styled.div({
  display: 'flex',
}, ({ isLatest }) => ({
  flexDirection: isLatest ? 'row' : 'column',
  '@media (max-width: 831px)': {
    flexDirection: 'column'
  }
}))

const Container = styled.div({
  padding: 24,
  marginBottom: 36,
})

const TextContainer = styled.div(props => ({
  display: 'flex',
  flexDirection: 'column',
  marginLeft: props.isLatest ? 32 : 0,
  '@media (max-width: 831px)': {
    marginLeft: 0,
  }
}))

const Description = styled(Paragraph)({

})

const PublishInfo = styled.div({
  display: 'flex',
  alignItems: 'center',
  marginTop: 16,
})

const Author = styled(Paragraph)({
  fontWeight: 700,
  color: Colors.PRIMARY,
  lineHeight: 1,
  marginBottom: 8,
})

const StyledDate = styled(Paragraph)({
  color: Colors.BLACK,
  lineHeight: 1,
})

const BlogLink = ({
  entryFields,
  entrySys,
  isLatest,
}) => {
  const {
    title,
    createdOn,
    description,
    image,
    author,
    slug,
    categories,
  } = entryFields
  const {
    id
  } = entrySys

  const PostImage = styled.div(props => ({
    background: `url(https:${image.fields.file.url}), ${Colors.PRIMARY}`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    width: '100%',
    height: props.isLatest ? 480 : 248,
    borderRadius: 8,
    '@media (max-width: 831px)': {
      height: 248,
    }
  }))

  const StyledLink = styled(Link)({
    textDecoration: 'none',
    ':hover': {
      [PostImage]: {
        boxShadow: `0 0 0 4px ${transparentize(0, Colors.PRIMARY)}`,
        borderRadius: 8,
      }
    }
  })

  return (
    <Container>
      <StyledLink to={`/blog/post/${slug}-${id}`} id="blog--link">
        <BlogLinkContainer isLatest={isLatest}>
          <PostImage isLatest={isLatest} />
          <TextContainer isLatest={isLatest}>
            <div style={{ margin: '12px 0' }}>
              {categories && categories.map(({ fields, sys }) => (
                <CategoryTag key={sys.id} label={fields.name} />
              ))}
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <H2>
                { title }
              </H2>
              <Description>
                { description }
              </Description>
              <PublishInfo>
                <AuthorAvatar image={author.fields.avatar.fields.file.url} />
                <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 16 }}>
                  <Author>
                    { author.fields.name }
                  </Author>
                  <StyledDate>
                    { format(new Date(createdOn), 'PPP') }
                  </StyledDate>
                </div>
              </PublishInfo>
            </div>
          </TextContainer>
        </BlogLinkContainer>
      </StyledLink>
    </Container>
  )
}

BlogLink.propTypes = {
  entryFields: PropTypes.object.isRequired,
  entrySys: PropTypes.object.isRequired,
  isLatest: PropTypes.bool,
}

BlogLink.defaultProps = {
  isLatest: false,
}

export default BlogLink
