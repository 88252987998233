import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import styled from '@emotion/styled'
import { transparentize } from 'polished'

// import { GridContainer, ContentContainer } from '../../components/Grid'
import { Colors } from '../../utils/styles'

const NavBar = styled.div({
  backgroundColor: Colors.WHITE,
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  borderBottom: `1px solid ${transparentize(0.9, Colors.BLACK)}`,
  padding: 24,
})

const NavBarLinks = styled.ul({
  listStyleType: 'none',
})

const NavItems = styled.li(
  {
    display: 'inline',
    fontWeight: 500,
    padding: 24,
    ':hover': {
      cursor: 'pointer',
    },
  },
  ({ isActive }) => ({
    borderBottom: isActive ? `2px solid ${Colors.PRIMARY}` : null,
    color: isActive ? Colors.PRIMARY : Colors.BLACK,
    fontWeight: 700,
  })
)

const Title = styled.div({
  color: Colors.PRIMARY,
  textTransform: 'uppercase',
  fontSize: 20,
  fontWeight: 700,
  margin: '0 12px',
})

const BlogNav = ({ client, setCategoryFilter, categoryFilter }) => {
  const [categories, setCategories] = useState([])

  useEffect(() => {
    client.getEntries().then(({ items }) => {
      const sortedEntries = _.orderBy(items, entry => entry.fields.sortOrder, [
        'asc',
      ]).filter(({ sys }) => sys.contentType.sys.id === 'categories')

      setCategories(sortedEntries)
    })
  }, [])

  if (!categories.length) return null

  return (
    <NavBar>
      <Title>Blog</Title>
      <NavBarLinks>
        {categories.map(({ fields }) => {
          const { name } = fields
          const isActive = categoryFilter === name
          return (
            <NavItems
              isActive={isActive}
              key={name}
              onClick={() => setCategoryFilter(name)}
            >
              {name}
            </NavItems>
          )
        })}
      </NavBarLinks>
    </NavBar>
  )
}

BlogNav.propTypes = {
  client: PropTypes.object.isRequired,
  setCategoryFilter: PropTypes.func.isRequired,
  categoryFilter: PropTypes.string.isRequired,
}

export default BlogNav
